import { Location } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { BenefitService } from 'src/app/component/benefit/benefit.service'
import { CelebrationReservationService } from 'src/app/component/celebration-reservation/celebration-reservation.service'
import { NavigationService } from 'src/app/component/navigation/navigation.service'
import { ReservationService } from 'src/app/component/reservation/reservation.service'
import { Venue } from 'src/app/component/venue/venue.interface'
import { WaitlistService } from 'src/app/component/waitlist/waitlist.service'
import { VenuePageService } from './venue-page.service'
import { ModalService } from '../../component/modal/modal.service'
import { Slide } from 'src/app/component/slide/slide.interface'
import { CreditCardService } from 'src/app/component/credit-card/credit-card.service'
import { FilterService } from 'src/app/component/filter/filter.service'
import { environment } from 'src/environments/environment'
import { DomSanitizer, Title } from '@angular/platform-browser'
import { ClientAuthService } from 'src/app/helper/client-auth.service'
import { PREFIX_TITLE } from 'src/app/common/prefix.title'
import { isCelebrationsOnly } from 'src/app/component/venue/is-only-celebrations'

@Component({
	selector: 'app-venue-page',
	templateUrl: './venue-page.component.html',
	styleUrls: ['./venue-page.component.scss'],
})
export class VenuePageComponent implements OnInit, OnDestroy {
	public env = environment
	public isAcessingFromCelebration: boolean = false
	public subs = new Subscription()
	public venue: Venue | null = null
	public tab: string = 'reservation'
	public slides: Slide[] = []
	public voucherValue: number | null = null

	public venueHasCelebrations = false

	constructor(
		public navigationService: NavigationService,
		public venueService: VenuePageService,
		public activatedRoute: ActivatedRoute,
		public reservationService: ReservationService,
		public celebrationReservationService: CelebrationReservationService,
		public waitlistService: WaitlistService,
		public router: Router,
		public benefitService: BenefitService,
		public location: Location,
		public modalService: ModalService,
		public creditCardService: CreditCardService,
		public filterService: FilterService,
		public domSanitizer: DomSanitizer,
		public clientAuthService: ClientAuthService,
		private title: Title
	) {}

	ngOnInit() {
		window.scrollTo(0, 0)

		this.subs.add(
			this.clientAuthService.voucher$.subscribe(voucher => {
				this.voucherValue = voucher?.value
			})
		)

		this.clientAuthService.init()

		this.isAcessingFromCelebration = this.benefitService.isAcessingFromCelebration.getValue()

		if (this.isAcessingFromCelebration) this.hasCelebration()

		const id = this.activatedRoute.snapshot.params['id']
		this.subs.add(this.venueService.getByID(id).subscribe(res => this.venueService.sendVenue(res)))

		// on initial need, this will be duplicated, but it is necessary for anyone changing category
		this.subs.add(
			this.clientAuthService.receiveClient().subscribe(res => {
				this.venueService.getByID(id).subscribe(res => this.venueService.sendVenue(res))
			})
		)

		this.subs.add(
			this.venueService.receiveVenue().subscribe(res => {
				this.venue = res
				if (this.venue?.name) {
					this.title.setTitle(`${PREFIX_TITLE} - ${this.venue.name}`)
				}
				this.venueHasCelebrations =
					this.venue?.partner?.types.some(item => item.type.toLowerCase() === 'celebrations') ?? false
				this.removeDiscountBenefitFromAspiracionais()
				// se o cliente não estiver logado e a venue só possui celebrations, volta para a home
				if (!this.clientAuthService.getClient() && isCelebrationsOnly(this.venue)) {
					this.router.navigate(['/'])
				}
				// se o cliente estiver logado e não possui celebrations para venues que só possui celebrations, volta pra home
				if (
					this.clientAuthService.getClient() &&
					!this.clientAuthService.getClient()?.vouchers?.length &&
					isCelebrationsOnly(this.venue)
				) {
					this.router.navigate(['/'])
				}
				this.slides = [...(res?.partner.images.banners || [])].map(item => {
					return {
						title: '',
						text: '',
						alt: '',
						buttonText: '',
						imageDesktop: `${this.env.tagmepub}/pubimg/${item.coverUrl}`,
						imageMobile: null,
						router: null,
						href: null,
					}
				})
			})
		)
	}

	removeDiscountBenefitFromAspiracionais() {
		if (!this.venue) return

		const hasTypeAspiracional = this.venue?.partner.types.find(
			item => item.type.toLowerCase() === 'aspiracional'
		)

		if (!hasTypeAspiracional) return

		this.venue.benefits = this.venue.benefits.filter(item => item.type.toLowerCase() !== 'discount')
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
		this.venueService.sendVenue(null)
	}

	hasCelebration() {
		const id = this.activatedRoute.snapshot.params['id']
		const client = this.clientAuthService.getClient()

		if (!client || !(client.vouchers || []).length) this.router.navigate([`/restaurante/${id}`])
	}

	startReservation() {
		this.isAcessingFromCelebration
			? this.celebrationReservationService.setReservationByKey('venue', this.venue)
			: this.reservationService.setReservationByKey('venue', this.venue)
		this.navigationService.setActivatedRightbarBySlug(
			this.isAcessingFromCelebration ? 'celebration-reservation-form' : 'reservation-form'
		)
	}

	startWaitlist() {
		this.waitlistService.setWaitlistByKey('venue', this.venue)
		this.navigationService.setActivatedRightbarBySlug('waitlist-form')
	}

	getGoogleMapsByLocation(location: Array<string | number>) {
		if (!location.length) return ''
		const latLong = `${location[1] || 0},${location[0] || 0}`
		return `https://maps.googleapis.com/maps/api/staticmap?markers=color:red|${latLong}&center=${latLong}&zoom=18&key=${this.env.googleMapsAPIToken}&size=568x398`
	}

	isFavorited() {
		return (this.clientAuthService.getClient()?.favorites || []).find(venueID => venueID === this.venue?._id)
	}

	sortOperationHours(operationHours: any) {
		return (operationHours || []).sort((a: any, b: any) => a.day - b.day)
	}

	getWeekdayNameByWeekNumber(weekdayNumber: string): string {
		switch (weekdayNumber) {
			case '1':
				return 'Segunda'
			case '2':
				return 'Terça'
			case '3':
				return 'Quarta'
			case '4':
				return 'Quinta'
			case '5':
				return 'Sexta'
			case '6':
				return 'Sábado'
			case '7':
				return 'Domingo'
		}
		return ''
	}
}
