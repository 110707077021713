import { Venue } from './venue.interface'

export function isCelebrationsOnly(venue: Venue | null) {
	if (!venue) return false
	const validCategories = ['Celebrations', 'Guia Michelin']
	return (
		venue.partner.types.every(category => validCategories.includes(category.type)) &&
		venue.partner.types.some(category => category.type === 'Celebrations') &&
		Array.isArray(venue.benefits) &&
		venue.benefits.length === 0
	)
}
