import { Injectable } from '@angular/core'
import { BehaviorSubject, map, Observable, of, switchMap, tap } from 'rxjs'
import { environment as env } from 'src/environments/environment'
import { Client } from '../component/client/client.interface'
import { HttpService } from '../component/helper/http.service'
import { SharedService } from '../component/helper/shared.service'
import { CreditCard } from '../component/credit-card/credit-card.interface'

export const LOCAL_CLIENT_ID = 'clientId'
export const VERSION = '3'
export const CENTURION_CATEGORY_ID = '64d3f4f23ef0bd49cf0f3a82'

@Injectable({
	providedIn: 'root',
})
export class ClientAuthService {
	redirectTimes = 0
	client = new BehaviorSubject<Client | null>(null)
	client$ = this.client.asObservable()

	selectedCategory$ = this.client.asObservable().pipe(
		map(client => client?.categories),
		map(categories => categories?.find(category => category.selected))
	)

	voucher$ = this.client.asObservable().pipe(
		map(client => {
			if (client?.vouchers.length) {
				return client.vouchers[0]
			}
			return null
		})
	)

	hasRealCard$ = this.selectedCategory$.pipe(map(category => Boolean(category?.gatewayId)))

	constructor(private sharedService: SharedService, private httpService: HttpService) {
		this.init()
	}

	public init() {
		const stored = this.sharedService.localStorage.get('client')
		if (stored) this.setClient(stored)
	}

	public setClient(client: Client | null) {
		if (client) {
			client.categories = this.getClientWithFormatedCategories(client) || []
			window.localStorage.setItem(LOCAL_CLIENT_ID, client._id)
		}
		document.body.classList[this.hasCenturion(client?.categories) ? 'add' : 'remove']('centurion-mode')
		if (client?.access_token?.token) {
			this.sharedService.localStorage.set('client_token', client.access_token.token)
		}

		this.sharedService.localStorage.set('client', client ? client : {})
		this.client.next(client)
	}

	private hasCenturion(categories: CreditCard[] | undefined) {
		if (!categories) return false
		return categories.some(category => category.categoryId === CENTURION_CATEGORY_ID)
	}

	public receiveClient() {
		return this.client.asObservable()
	}

	public getClient(): Client | null {
		return this.client.getValue()
	}

	public get getToken() {
		return this.sharedService.localStorage.get('client_token')
	}

	getClientWithFormatedCategories(client: Client | null) {
		if (!client?.categories) return null
		const selectedCategory = this.getSelectedCategory()
		if (selectedCategory) {
			const result = client.categories.map(item => ({
				...item,
				image: '',
				selected: selectedCategory.categoryId === item.categoryId,
			}))
			return result
		}
		const hasMainCard = client.categories.find(category => category.mainCard)
		if (hasMainCard) {
			return client.categories.map(item => ({
				...item,
				image: '',
				selected: hasMainCard.categoryId === item.categoryId,
			}))
		}
		return client.categories.map((item, index) => ({ ...item, image: '', selected: index === 0 }))
	}

	public login(form: any): Observable<any> {
		return this.httpService.post<any>('api/v1/auth/bradesco', { ...form, partnerId: env.partnerID }).pipe(
			tap(client => {
				this.setClient(client)
				this.setLocalVersion()
			})
		)
	}

	public loginPassword(body: { email: string; phone: string; password: string }) {
		return this.httpService.post('api/v1/auth/loginPassword', body).pipe(
			tap(client => {
				this.setClient(client as Client)
				this.setLocalVersion()
			})
		)
	}

	public loginUUID(uuid: string) {
		return this.httpService.get(`api/v1/auth/login/${uuid}`).pipe(
			tap(client => {
				this.setClient(client as Client)
				this.setLocalVersion()
			})
		)
	}

	public update(form: any, id: string): Observable<any> {
		return this.httpService.patch(`api/v1/clients/${id}`, form)
	}

	public updateClientFront(): Observable<any> {
		const client = this.client.getValue()
		if (!client) return of()
		const clientId = client._id
		return this.httpService
			.get<any>(`api/v1/clients/bradesco/${clientId}`, { partnerId: env.partnerID })
			.pipe(
				tap(client => {
					this.setClient(client)
					this.setLocalVersion()
				})
			)
	}

	public isLoggedIn() {
		const client = this.client.getValue()
		if (!client) throw new Error('Não é possível atualizar cliente.')
		return this.httpService.get(`api/v1/auth/isLoggedIn`)
	}

	private setLocalVersion() {
		localStorage.setItem('version', VERSION)
	}

	public updatePassowrd(body: { clientId: string; password: string }) {
		return this.httpService.patch('api/v1/clients/updatePassword', body)
	}

	public sendPasswordLink(body: { email: string; phone: string }) {
		return this.httpService.post('api/v1/clients/sendPasswordLink', { ...body, partnerId: env.partnerID })
	}

	public isLogged() {
		return this.sharedService.localStorage.get('client')
	}

	public logout() {
		this.sharedService.localStorage.remove('client')
		this.sharedService.localStorage.remove('filter')
		this.sharedService.localStorage.remove('reservation')
		this.sharedService.localStorage.remove('waitlist')
		this.sharedService.localStorage.remove('celebration_reservation')
		this.sharedService.localStorage.remove('creditCard')
		this.sharedService.localStorage.remove('client_token')
		this.setClient(null)

		// TODO: Mudar para router.navigation(['/'])
		window.location.href = '/'
	}

	getClientByTerm(form: any): Observable<Client[]> {
		return this.httpService.get<Client[]>(`api/v1/clients`, form)
	}

	getFormatedPhone() {
		return this.client.getValue()?.phone.substring(2) || ''
	}

	getSelectedCategory() {
		const client = this.sharedService.localStorage.get('client')
		if (!client?.categories) return undefined
		return client.categories.find((item: CreditCard) => item.selected)
	}

	setSelectedCategory(category: CreditCard) {
		const client = this.sharedService.localStorage.get('client')
		if (!client) return
		client.categories = (client.categories || []).map((item: CreditCard) => ({
			...item,
			selected: category.categoryId === item.categoryId,
		}))

		// Não estou usando o método this.setClient pois o método da conflito com a verificação this.getClientWithFormatedCategories
		this.sharedService.localStorage.set('client', client ? client : {})
		this.client.next(client)
	}

	// On Order History we only have categoryId
	setSelectedCategoryId(categoryId: string) {
		const client = this.client.getValue()
		if (!client) {
			return
		}
		client.categories = (client.categories || []).map((item: CreditCard) => ({
			...item,
			selected: categoryId === item.categoryId,
		}))

		this.sharedService.localStorage.set('client', client ? client : {})
		this.client.next(client)
	}

	// After completing the registration, we need to select the category
	setSelectedCategoryByLastFourDigits(lastFourDigits: string) {
		const client = this.client.getValue()
		if (!client) {
			return
		}
		client.categories = (client.categories || []).map((item: CreditCard) => ({
			...item,
			selected: lastFourDigits == item.lastFourDigits,
		}))

		this.sharedService.localStorage.set('client', client ? client : {})
		this.client.next(client)
	}

	resendCode(body: { email: string; phone: string }): Observable<any> {
		return this.httpService.post('api/v1/clients/sendValidationCode', {
			...body,
			partnerId: env.partnerID,
		})
	}

	setMainCard(lastFourDigits: string): Observable<any> {
		const client = this.client.getValue()
		if (!client) throw new Error('Não é possível atualizar cliente.')
		const clientId = client._id
		return this.httpService
			.patch(`api/v1/payments/main-card/${clientId}/${lastFourDigits}`, {})
			.pipe(switchMap(() => this.updateClientFront()))
	}
}
